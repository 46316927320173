// @ts-nocheck
import React from 'react'
import AntForm, { AntFormRailsNested } from "@9troisquarts/ant-form";
import { isDev } from "../../../../utils/_utils";
import TranslationProvider from "../../../../utils/TranslationProvider";
import en from 'antd/lib/locale/en_US';
import graphqlClient from "../../../../utils/graphqlClient";
import { ConfigProvider } from "antd";
import { NtqToolbar, NtqToolbarProvider } from '@9troisquarts/wand';
import EnvironmentBanner from '../EnvironmentBanner';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

AntForm.configure({
  locale: 'en',
  formProps: {
    layout: 'vertical',
  },
  actionsWrapperProps: {
    className: 'ant-form-actions-wrapper'
  }
});

AntForm.addField('nested', {
  component: AntFormRailsNested,
  showFormItemError: false,
});

type IProps = {
  user: any;
}

const { client } = graphqlClient({ url: '/graphql' });

const AppProvider: React.FC<IProps> = props => {
  const {
    environment
  } = props;
  return (
    <ConfigProvider locale={en}>
      <NtqToolbarProvider enabled={isDev} impersonation> 
        <BrowserRouter history={history}>
          <ApolloProvider client={client}>
            <NtqToolbar enabled={isDev} />
            <EnvironmentBanner environment={environment} />
            {props.children}
          </ApolloProvider>
        </BrowserRouter>
      </NtqToolbarProvider>
    </ConfigProvider>
  )
}

export default TranslationProvider(AppProvider);
